import { cleanPath } from "@/utils/functions"
import Link from "next/link"
import { Suspense } from "react"
import { BikeCardContent } from "./BikeCardContent"

export const BikeCard = props => {
  const { element, origin } = props
  const { url } = element
  const { isNotBot } = props

  return (
    <Link
      prefetch={isNotBot}
      href={{
        pathname: cleanPath(url),
        query: { rel: origin }
      }}
      as={cleanPath(url)}
      style={{ textDecoration: `none` }}
      passHref
    >
      <Suspense>
        <BikeCardContent {...props} />
      </Suspense>
    </Link>
  )
}
