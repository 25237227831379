'use client'

import { TWButton } from '@/components/tailwind/Button'
import { useSearchMotos } from "@/hooks/searchMotos"
import IconsSVG from "@/utils/icons/IconsSVG"
import MetaMoto from "@web/_components/home/subcomponents/menu/select/MetaMoto"
import Link from "next/link"
import { useContext, useEffect } from "react"
import { SVGImage } from "../../styles/Images"
import { useRouter } from 'next/navigation'
import { ToolsContext } from '@/context/tools/tools'

export const SearchMotos = ({ ...props }) => {
  const {
    hostname, category = false
  } = props
  const { isNotBot } = hostname
  const { tools: { texts, colors }} = useContext(ToolsContext)
  const { moto_search } = texts
  const { mobile } = hostname
  const router = useRouter()
  const {
    path, createPath, brand, getBrand, model, getModel, year, getYear, brandId, setBrandId, modelId, setModelId, yearId, setYearId
  } = useSearchMotos()

  const handleClickItem = (key, id) => {
    switch (key) {
    case `b`:
      setBrandId(id)
      break
    case `m`:
      setModelId(id)
      break
    case `y`:
      setYearId(id)
      break
    default:
      break
    }
  }

  useEffect(() => {
    (async() => {
      if (brand?.length === 0) {
        await getBrand(hostname?.langId)
      }
    })()
  }, [brand])

  useEffect(() => {
    (async() => {
      if (brandId !== null) {
        await getModel(false, hostname?.langId)
        await getModel(true, hostname?.langId)
      } else {
        await getModel(false, hostname?.langId)
      }
    })()
  }, [brandId])

  useEffect(() => {
    (async() => {
      if (modelId !== null) {
        await getYear(false)
        await getYear(true)
      } else {
        await getYear(false)
      }
    })()
  }, [modelId])

  useEffect(() => {
    createPath(category, router)
  }, [yearId])

  return (
    <div className={`flex justify-center items-center top-0 ${!category && `1200:top-[-50px] 1200:relative 1200:z-50`}`} >
      <div className={`grid grid-cols-1 border-[1px] 1200:grid-cols-6 gap-x-6 w-full justify-between items-center py-3 px-5 1200:pl-10 1200:pr-3 1200:py-3 shadow-md shadow-neutral-400 bg-slate-100 1200:rounded-full text-sm 1380:text-base 1200:w-[90%] 1200:bg-white`}
        style={{ borderColor: category && colors.lightGrey3 }}
      >
        <p className="mb-0 tablet:mb-1 pl-0 font-bold text-center hidden 1200:block 1200:col-span-2 1200:text-left">
          {moto_search?.title || `Busca accesorios y recambios para tu moto`}
        </p>
        <MetaMoto
          items={brand}
          disabled={false}
          itemFilter={moto_search?.brand || `marca`}
          text={brand?.find(x => x.id === brandId)?.name}
          handleClickItem={handleClickItem}
          model="b"
          mobile={!mobile}
          {...{ category, colors }} />
        <MetaMoto
          items={model}
          disabled={brandId === null}
          itemFilter={moto_search?.model || `modelo`}
          text={model?.find(x => x.id === modelId) && `${model?.find(x => x.id === modelId)?.name} ${model?.find(x => x.id === modelId)?.cc}`}
          handleClickItem={handleClickItem}
          model="m"
          mobile={!mobile}
          {...{ category, colors }}
        />
        <MetaMoto
          items={year}
          disabled={modelId === null}
          itemFilter={moto_search?.year || `año`}
          text={year?.find(x => x.id === yearId)?.name}
          handleClickItem={handleClickItem}
          model="y"
          mobile={!mobile}
          {...{ category, colors }}
        />
        {!category && <div className=" text-center my-1 1200:my-0 1200:flex 1200:justify-end 1200:col-span-1">
          <Link prefetch={isNotBot} href={path} passHref scroll={true} className="flex justify-center">
            <TWButton {...{ colors }}
              className="px-5 py-2 1200:py-3 1200:px-2 w-full 1200:w-auto"
              disabled={!yearId}
              aria-label="find parts for your motorcycle">
              <SVGImage size="25px" className="hidden 1200:block">
                <IconsSVG name="search-meta-moto" fillcolor={colors.mainContrast} path="img/client-icons/web-search.svg" />
              </SVGImage>
              <p className="mb-0 tablet:mb-1 max-w-80 tablet:max-w-full text-center pl-0 font-semibold tablet:text-base text-white uppercase block 1200:hidden ">
                {moto_search?.button || `buscar`}
              </p>
            </TWButton>
          </Link>
        </div>}
      </div>
    </div>
  )
}
